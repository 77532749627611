import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import dateFormat from 'dateformat'

import map00 from '../assets/images/MapCC_2024.png'

class SchedulePage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            eventData: this.props.data.allMarkdownRemark.edges.map(edge => edge.node),
            rooms: [],
            schedule: [],
            newSchedule: {},
            sessionTypes: [],
            selectedSession: {
                title: null,
                speaker: null,
                description: null
            },
            selectedDate: null,
            selectedSessionType: null
        }

        if (props.location.state) {
            this.state.selectedDate = props.location.state.selectedDate;
            this.state.selectedSessionType = props.location.state.selectedSessionType;
        }
    }

    componentDidMount = function() {
        if (!this.state.selectedDate) {
            this.setState({
                selectedDate: '2024-05-03'
            })
        }
        if (!this.state.selectedSessionType) {
            this.setState({
                selectedSessionType: 'All'
            })
        }
        this.setState({
            schedule: this.parseEvents()
        })
    }



    /* Action Methods */
    selectDate = function(date) {
        this.setState({
            selectedDate: date
        })
        return false
    }

    selectSessionType = function(type) {
        this.setState({
            selectedSessionType: type
        })
        return false
    }



    /* Formatting Methods */
    parseEvents = function() {
        let schedule = []
        let rooms = []
        let sessionTypes = [
            'All',
            'Workshop',
            'Vendor Workshop',
            'Activity',
            'Services'
        ]

        this.state.eventData.map(event => {
            let startDateTime = this.parseDateString(event.frontmatter.startDateTime)
            let endDateTime = this.parseDateString(event.frontmatter.endDateTime)
            let startDate = this.formatDate(startDateTime)
            let endDate = this.formatDate(endDateTime)
            let startTime = this.formatTime(startDateTime)
            let endTime = this.formatTime(endDateTime)
            let timeRange = startTime + ' - ' +endTime

            if (rooms.indexOf(event.frontmatter.room) === -1) {
                rooms.push(event.frontmatter.room)
            }
            if (typeof schedule[startDate] === 'undefined') {
                schedule[startDate] = {
                    date: startDate,
                    title: this.formatDate(startDateTime, 'ddd, mmmm d'),
                    timeSlots: {}
                }
            }
            if (typeof schedule[startDate].timeSlots[timeRange] === 'undefined') {
                schedule[startDate].timeSlots[timeRange] = {
                    title: timeRange,
                    sessions: []
                }
            }
            schedule[startDate].timeSlots[timeRange].sessions.push({
                fileName: event.fileAbsolutePath.split('/').pop().slice(0,-3),
                startTime: startTime,
                endTime: endTime,
                timeRange: startTime + ' - ' +endTime,
                title: event.frontmatter.title,
                excerpt: event.excerpt,
                body: event.html,
                sessionType: event.frontmatter.sessionType,
                speaker: event.frontmatter.speaker,
                room: event.frontmatter.room
            })
        })

        this.setState({
            rooms,
            sessionTypes
        })

        return schedule
    }

    parseDateString(date) {
        let p = date.split(/\D/)
        return new Date(p[0], p[1]-1, p[2], p[3], p[4], '00')
    }

    formatDate(date, format = 'yyyy-mm-dd') {
        return dateFormat(date, format)
    }

    formatTime(date, format = 'h:MMtt') {
        return dateFormat(date, format).replace('am',' a.m.').replace('pm',' p.m.')
    }

    slugify = function(str) {
        return str.replace(' ','-').toLowerCase()
    }

    /* Render Methods */
    renderScheduleTable() {
        let schedule = this.state.schedule
        let dateTabs = []
        let dateBodies = []
        let typeTabs = []

        Object.keys(schedule).forEach((date, index) => {
            let isSelected = schedule[date].date == this.state.selectedDate

            dateTabs.push(
                <a href="#"
                    className={"button" + (isSelected ? " special": "")}
                    onClick={() => this.selectDate(schedule[date].date)}
                    key={'datetab-' + index}>
                    {schedule[date].title}
                </a>
            )
            dateBodies.push( this.renderDateBody(schedule[date]) )
        })

        this.state.sessionTypes.forEach((type, index) => {
            let isSelected = type == this.state.selectedSessionType
            typeTabs.push(
                <a href="#"
                    className={"button small" + (isSelected ? " special": "")}
                    onClick={() => this.selectSessionType(type)}
                    key={'typetab-' + index}>
                    {type}
                </a>
            )
        })

        return (
            <div>
                <div className={"grid-wrapper"} style={{
                    marginBottom: '8px'
                }}>
                    <div className="col-6 col-12-small">
                        {dateTabs}
                    </div>
                    <div className="col-6 col-12-small" style={{
                        marginTop: '14px',
                        textAlign: 'right'
                    }}>
                        <span style={{
                            fontSize: '12px',
                            fontWeight: 'bold',
                            marginRight: '14px',
                            textTransform: 'uppercase'
                        }}>Filter:</span>
                        {typeTabs}
                    </div>
                </div>
                <table>
                    {dateBodies}
                </table>
            </div>
        )
    }

    renderDateBody(dateObject) {
        if ( this.state.selectedDate == dateObject.date ) {
            return (
                <tbody key={dateObject.date}>
                    <tr>
                        <th>Time</th>
                        <th>Session</th>
                        <th>Room</th>
                    </tr>
                    {this.renderTimeSlots(dateObject.timeSlots)}
                </tbody>
            )
        }
    }

    renderTimeSlots(timeSlots) {
        let slotOutput = []
        Object.keys(timeSlots).forEach((slotId, index) => {
            let slotSessions = timeSlots[slotId].sessions.filter(session => session.sessionType == this.state.selectedSessionType || this.state.selectedSessionType == 'All')
            if ( slotSessions.length ) {
                slotSessions.forEach((session, sessionIndex) => {
                    slotOutput.push(
                        <tr key={'session-'+index+'-'+sessionIndex}>
                            <th>{sessionIndex === 0? timeSlots[slotId].title: ''}</th>
                            <th>
                                <Link
                                    to={`/schedule/${session.fileName}`}
                                    state={{
                                        'selectedDate': this.state.selectedDate,
                                        'selectedSessionType': this.state.selectedSessionType
                                    }}>
                                    {session.speaker}: {session.title}
                                </Link>
                            </th>
                            <th>{session.room.replace(/0([\d]+)/g,'$1')}</th>
                        </tr>
                    )
                })
            }
        })
        return slotOutput
    }

    render() {
        return (
            <Layout>
                <Helmet>
                    <title>2024 CHCSD Schedule</title>
                    <meta Name="description" content="2024 CHCSD Schedule Page" />
                </Helmet>

                <div id="main" className="alt">

                    <section id="one">
                        <div className="inner">
                            <header className="major">
                                <h1>2024 Schedule</h1>
                            </header>
                            <header className="minor">
                                <h4>Schedule subject to change until start of the conference...</h4>
                                <h4><a href="https://teachsd.sharepoint.com/:b:/s/CHCSD/EVvxwRlyNFtAugVciZdepFYBJzRYoZLzQgpXKbE6u0cfcw?e=Klwe0p" target="_blank">Current Printable Schedule</a></h4>
                                <h4>See map of the convention space at bottom of page.</h4>
                            </header>

                            { this.renderScheduleTable() }
                        </div>
                    </section>
                    <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h1>Map</h1>
                            </header>
                            <span className="image main"><img src={map00} alt="" /></span>
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

export default SchedulePage

export const query = graphql`
    query {
        allMarkdownRemark(
            sort: {
                fields: [
                    frontmatter___startDateTime
                    frontmatter___room
                ]
                order: [
                    ASC
                    ASC
                ]
            }
        ) {
            edges {
                node {
                    id
                    fileAbsolutePath
                    frontmatter {
                        title
                        startDateTime
                        endDateTime
                        room
                        speaker
                        sessionType
                    }
                    excerpt(
                        pruneLength: 120
                        truncate: true
                        format: HTML
                    )
                    html
                }
            }
        }
    }
`
